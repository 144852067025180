import React from "react";
import { Box, Grid, Paper } from "@mui/material";
import testimonialsData from "./data.json";
import TestimonialCard from "../CustomCard/TestimonialCard";
import Title from "../CommonComponents/Title/Title";

function Testimonials() {
  return (
    <Paper
      id="testimonials"
      sx={{
        position: "relative",
        display: "block",
        padding: "65px 0px",
        backgroundImage: `url(${testimonialsData.bgImage})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Box sx={{ textAlign: "center", marginBottom: "45px" }}>
        <Title title={testimonialsData.name} />
      </Box>
      <Grid container spacing={2} className="container">
        {testimonialsData.cards &&
          testimonialsData.cards.length > 0 &&
          testimonialsData.cards.map((item, index) => {
            return (
              <TestimonialCard
                item={item}
                xs={12}
                sm={6}
                md={4}
                lg={3}
                xl={3}
                index={index}
                key={index}
              />
            );
          })}
      </Grid>
    </Paper>
  );
}

export default Testimonials;
