import React, { useState } from "react";
import "./SocialMediaStickyIcons.css";
import { Box, IconButton, List, ListItem } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import constants from "../../constants/constant";
import SocialMediaStickyIconsData from "./data.json";

function SocialMediaStickyIcons() {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  return (
    <Box component="div" sx={{ position: "fixed", top: "35%", zIndex: "1" }}>
      <Box
        component="div"
        sx={{ backgroundColor: constants.themeColor, borderRadius: "10px" }}
      >
        <IconButton
          aria-label="delete"
          size="large"
          onClick={toggleVisibility}
          sx={{ color: "white", padding: "0px" }}
        >
          {!isVisible ? (
            <ArrowRightIcon sx={{ fontSize: "50px" }} />
          ) : (
            <ArrowLeftIcon sx={{ fontSize: "50px" }} />
          )}
        </IconButton>
      </Box>
      <Box
        component="div"
        className={`social-media-icons ${isVisible ? "visible" : ""}`}
        sx={{ borderRadius: "10px", color: "white", padding: "0px" }}
      >
        <List>
          <ListItem
            component="a"
            href={SocialMediaStickyIconsData.socialMedia.facebook.value}
            target="_blank"
          >
            <FacebookIcon
              sx={{ fontSize: "45px", color: constants.themeColor }}
            />
          </ListItem>
          <ListItem
            component="a"
            href={SocialMediaStickyIconsData.socialMedia.twitter.value}
            target="_blank"
          >
            <TwitterIcon
              sx={{ fontSize: "45px", color: constants.themeColor }}
            />
          </ListItem>
          <ListItem
            component="a"
            href={SocialMediaStickyIconsData.socialMedia.linkedIn.value}
            target="_blank"
          >
            <LinkedInIcon
              sx={{ fontSize: "45px", color: constants.themeColor }}
            />
          </ListItem>
        </List>
      </Box>
    </Box>
  );
}

export default SocialMediaStickyIcons;
