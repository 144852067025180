import React from "react";
import { Box, Grid, Paper } from "@mui/material";
import galleryData from "./data.json";
import "./Gallery.css";
import GalleryCard from "../CustomCard/GalleryCard";
import Title from "../CommonComponents/Title/Title";

// function srcset(image, width, height, rows = 1, cols = 1) {
//   return {
//     src: `${image}?w=${width * cols}&h=${height * rows}&fit=crop&auto=format`,
//     srcSet: `${image}?w=${width * cols}&h=${
//       height * rows
//     }&fit=crop&auto=format&dpr=2 2x`,
//   };
// }

function Gallery() {
  // const itemData = [
  //   {
  //     img: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
  //     title: "Breakfast",
  //     author: "@bkristastucchio",
  //     featured: true,
  //   },
  //   {
  //     img: "https://images.unsplash.com/photo-1551782450-a2132b4ba21d",
  //     title: "Burger",
  //     author: "@rollelflex_graphy726",
  //   },
  //   {
  //     img: "https://images.unsplash.com/photo-1522770179533-24471fcdba45",
  //     title: "Camera",
  //     author: "@helloimnik",
  //   },
  //   {
  //     img: "https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c",
  //     title: "Coffee",
  //     author: "@nolanissac",
  //   },
  //   {
  //     img: "https://images.unsplash.com/photo-1533827432537-70133748f5c8",
  //     title: "Hats",
  //     author: "@hjrc33",
  //   },
  //   {
  //     img: "https://images.unsplash.com/photo-1558642452-9d2a7deb7f62",
  //     title: "Honey",
  //     author: "@arwinneil",
  //     featured: true,
  //   },
  //   {
  //     img: "https://images.unsplash.com/photo-1516802273409-68526ee1bdd6",
  //     title: "Basketball",
  //     author: "@tjdragotta",
  //   },
  //   {
  //     img: "https://images.unsplash.com/photo-1518756131217-31eb79b20e8f",
  //     title: "Fern",
  //     author: "@katie_wasserman",
  //   },
  //   {
  //     img: "https://images.unsplash.com/photo-1597645587822-e99fa5d45d25",
  //     title: "Mushrooms",
  //     author: "@silverdalex",
  //   },
  //   {
  //     img: "https://images.unsplash.com/photo-1567306301408-9b74779a11af",
  //     title: "Tomato basil",
  //     author: "@shelleypauls",
  //   },
  //   {
  //     img: "https://images.unsplash.com/photo-1471357674240-e1a485acb3e1",
  //     title: "Sea star",
  //     author: "@peterlaster",
  //   },
  //   {
  //     img: "https://images.unsplash.com/photo-1589118949245-7d38baf380d6",
  //     title: "Bike",
  //     author: "@southside_customs",
  //   },
  // ];

  return (
    // <Paper
    //   id="gallery"
    //   sx={{
    //     position: "relative",
    //     display: "block",
    //     padding: "65px 0px",
    //   }}
    // >
    //   <Typography
    //     sx={{
    //       typography: { sm: "h4", xs: "h5" },
    //       textDecoration: "underline",
    //       textAlign: "center",
    //     }}
    //     gutterBottom
    //   >
    //     Gallery
    //   </Typography>
    //   <ImageList
    //     sx={{
    //       // width: 500,
    //       // height: 450,
    //       // Promote the list into its own layer in Chrome. This costs memory, but helps keeping high FPS.
    //       transform: "translateZ(0)",
    //     }}
    //     id="gallery"
    //     //   rowHeight={200}
    //     gap={1}
    //   >
    //     {itemData.map((item) => {
    //       const cols = item.featured ? 2 : 1;
    //       const rows = item.featured ? 2 : 1;

    //       return (
    //         <ImageListItem key={item.img} cols={cols} rows={rows}>
    //           <img
    //             {...srcset(item.img, 250, 200, rows, cols)}
    //             alt={item.title}
    //             loading="lazy"
    //           />
    //           <ImageListItemBar
    //             sx={{
    //               background:
    //                 "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
    //                 "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
    //             }}
    //             title={item.title}
    //             position="top"
    //             actionIcon={
    //               <IconButton
    //                 sx={{ color: "white" }}
    //                 aria-label={`star ${item.title}`}
    //               >
    //                 <StarBorderIcon />
    //               </IconButton>
    //             }
    //             actionPosition="left"
    //           />
    //         </ImageListItem>
    //       );
    //     })}
    //   </ImageList>
    // </Paper>

    <Paper
      id="gallery"
      sx={{
        position: "relative",
        display: "block",
        padding: "65px 0px",
        backgroundImage: `url(${galleryData.bgImage})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Box sx={{ textAlign: "center", marginBottom: "45px" }}>
        <Title title={galleryData.name} />
      </Box>
      <Grid container spacing={2} className="container">
        {galleryData.cards &&
          galleryData.cards.length > 0 &&
          galleryData.cards.map((item, index) => {
            return (
              <GalleryCard
                item={item}
                xs={12}
                sm={6}
                md={4}
                lg={4}
                xl={4}
                index={index}
                key={index}
              />
            );
          })}
      </Grid>
    </Paper>
  );
}

export default Gallery;
