import React, { useMemo } from "react";
import { GoogleMap, useLoadScript } from "@react-google-maps/api";
import { Typography } from "@mui/material";
import "./CustomGoogleMap.css";

function CustomGoogleMap(props) {
  const { lat, lng } = props;
  const { isLoaded } = useLoadScript({
    // preventGoogleFontsLoading: true,
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
  });
  const center = useMemo(() => ({ lat: lat, lng: lng }), []);

  return (
    <>
      {!isLoaded ? (
        <Typography
          sx={{
            typography: { sm: "h4", xs: "h5" },
          }}
          gutterBottom
        >
          Loading...
        </Typography>
      ) : (
        <GoogleMap
          mapContainerClassName="google-map"
          center={center}
          zoom={12}
        />
      )}
    </>
  );
}

export default CustomGoogleMap;
