import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  Stack,
} from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import swDevelopmentDetailsData from "./data.json";
import React, { useEffect } from "react";
import SimpleCard from "../CustomCard/SimpleCard";
import "./SwDevelopmentDetails.css";
import Title from "../CommonComponents/Title/Title";

function SwDevelopmentDetails() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, []);

  return (
    <Paper
      id="rpoDetails"
      component={Stack}
      sx={{
        position: "relative",
        display: "block",
        // padding: "80px 0px",
        backgroundImage: `url(${swDevelopmentDetailsData.bgImage})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Grid xs={12} sm={12} md={6} sx={{ padding: "0px 15px" }}>
        <Title title={swDevelopmentDetailsData.name} />
      </Grid>
      <List>
        <ListItem>
          <ListItemText
            sx={{ textAlign: "justify" }}
            primary={swDevelopmentDetailsData.description}
          />
        </ListItem>
      </List>
      <Box
        sx={{
          textAlign: "center",
          margin: "20px 0px 40px 0px",
          padding: "0px 15px",
        }}
      >
        <Title title={swDevelopmentDetailsData.whyChose} />
      </Box>
      <Grid container spacing={2} className="container">
        {swDevelopmentDetailsData.whyChoseCards &&
          swDevelopmentDetailsData.whyChoseCards.length > 0 &&
          swDevelopmentDetailsData.whyChoseCards.map((item, index) => {
            return (
              <SimpleCard
                item={item}
                xs={12}
                sm={6}
                md={4}
                lg={4}
                xl={4}
                index={index}
                key={index}
              />
            );
          })}
      </Grid>
      <Grid
        xs={12}
        sm={12}
        md={6}
        sx={{ padding: "0px 15px", margin: "20px 0px 10px 0px" }}
      >
        <Title title={swDevelopmentDetailsData.servicesWeOffer} />
      </Grid>
      <List>
        {swDevelopmentDetailsData.services &&
          swDevelopmentDetailsData.services.length > 0 &&
          swDevelopmentDetailsData.services.map((item, index) => {
            return (
              <ListItem key={index}>
                <FiberManualRecordIcon fontSize="small" />
                {item}
              </ListItem>
            );
          })}
      </List>
      <Box sx={{ textAlign: "center", margin: "50px 0px" }}>
        <Title title={swDevelopmentDetailsData.whyPartner} />
      </Box>
      <Grid container spacing={2} className="container">
        {swDevelopmentDetailsData.whyPartnerCards &&
          swDevelopmentDetailsData.whyPartnerCards.length > 0 &&
          swDevelopmentDetailsData.whyPartnerCards.map((item, index) => {
            return (
              <SimpleCard
                item={item}
                xs={12}
                sm={6}
                md={4}
                lg={3}
                xl={3}
                index={index}
                key={index}
              />
            );
          })}
      </Grid>
      <Grid
        xs={12}
        sm={12}
        md={6}
        sx={{ padding: "0px 15px", margin: "20px 0px 10px 0px" }}
      >
        <Title title={swDevelopmentDetailsData.hereWeGo.name} />
      </Grid>
      <List>
        <ListItem>
          <ListItemText
            sx={{ textAlign: "justify" }}
            primary={swDevelopmentDetailsData.hereWeGo.description}
          />
        </ListItem>
      </List>
    </Paper>
  );
}

export default SwDevelopmentDetails;
