import {
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  Stack,
} from "@mui/material";
import React from "react";
import swDevelopmentData from "./data.json";
import { useNavigate } from "react-router-dom";
import constants from "../../constants/constant";
import Title from "../CommonComponents/Title/Title";

function SWDevelopment() {
  const navigate = useNavigate();

  return (
    <Paper
      component={Stack}
      id="swd"
      sx={{
        textAlign: "center",
        justifyContent: "center",
        position: "relative",
        display: "block",
        padding: "80px 0px",
        backgroundImage: `url(${swDevelopmentData.bgImage})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        // WebkitTransform: "scaleX(-1)",
        // transform: "scaleX(-1)",
      }}
    >
      <Grid container spacing={2}>
        <Grid xs={12} sm={12} md={6} sx={{ padding: "0px 20px" }}></Grid>
        <Grid
          xs={12}
          sm={12}
          md={6}
          sx={{ padding: "0px 20px", color: "white" }}
        >
          <Title title={swDevelopmentData.name} />
          <List>
            {swDevelopmentData &&
              swDevelopmentData.content &&
              swDevelopmentData.content.length > 0 &&
              swDevelopmentData.content.map((item, index) => {
                return (
                  <ListItem key={index}>
                    <ListItemText
                      sx={{ textAlign: "justify" }}
                      primary={item}
                    />
                  </ListItem>
                );
              })}
          </List>
        </Grid>
      </Grid>
      <Button
        variant="contained"
        sx={{
          alignSelf: "center",
          marginTop: "10vh",
          backgroundColor: constants.themeColor,
        }}
        onClick={() => navigate("/sw-development-details")}
      >
        {swDevelopmentData.seeMore}
      </Button>
    </Paper>
  );
}

export default SWDevelopment;
