import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  IconButton,
  CardHeader,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import { red } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import React, { useState, useEffect } from "react";
import constants from "../../constants/constant";
import "./TestimonialCard.css";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

function TestimonialCard(props) {
  const { item, xs, sm, md, lg, xl, index } = props;

  const [expanded, setExpanded] = React.useState(false);
  const [descriptionList, setDescriptionList] = useState([]);
  const [expandedItems, setExpandedItems] = useState([]);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const toggleExpand = (index) => {
    setExpandedItems((prevExpandedItems) =>
      prevExpandedItems.includes(index)
        ? prevExpandedItems.filter((item) => item !== index)
        : [...prevExpandedItems, index]
    );
  };

  useEffect(() => {
    setDescriptionList(item.description.split("\n"));
  }, []);

  return (
    <Grid
      xs={xs}
      sm={sm}
      md={md}
      lg={lg}
      xl={xl}
      sx={{ padding: "10px" }}
      key={index}
    >
      <Card
        className="testimonial-card"
        sx={{
          ":hover": {
            boxShadow: 20, // theme.shadows[20]
            transform: "scale(1.05)", // zoom on hover
          },
        }}
        // onClick={() => {
        //   toggleExpand(index);
        //   handleExpandClick();
        // }}
      >
        <CardActionArea>
          <CardHeader
            avatar={
              <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                {item.name.charAt(0)}
              </Avatar>
            }
            // action={
            //   <IconButton aria-label="settings">
            //     <MoreVertIcon />
            //   </IconButton>
            // }
            title={item.name}
            subheader={item.date}
          />
          <CardMedia
            component="img"
            height="194"
            image={item.image}
            alt="Paella dish"
          />
          <CardContent>
            <Typography variant="body2" color="text.secondary">
              {expandedItems.includes(index)
                ? item.title
                : `${item.title.slice(0, 100)}${
                    item.title.length > 100 ? "..." : ""
                  }`}
            </Typography>
          </CardContent>
          <CardActions disableSpacing>
            {/* <IconButton aria-label="add to favorites">
            <FavoriteIcon />
          </IconButton>
          <IconButton aria-label="share">
            <ShareIcon />
          </IconButton> */}
            <Button
              size="small"
              onClick={() => toggleExpand(index)}
              sx={{ color: constants.themeColor }}
            >
              {expandedItems.includes(index) ? "See less" : "See more"}
            </Button>
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </CardActions>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>
              <Typography paragraph>Description:</Typography>
              {descriptionList &&
                descriptionList.length > 0 &&
                descriptionList.map((i, ind) => {
                  return (
                    <Typography paragraph key={ind}>
                      {i}
                    </Typography>
                  );
                })}
            </CardContent>
          </Collapse>
        </CardActionArea>
      </Card>
    </Grid>
  );
}

export default TestimonialCard;
