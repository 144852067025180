import {
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  Stack,
} from "@mui/material";
import React from "react";
import rpoData from "./data.json";
import { useNavigate } from "react-router-dom";
import constants from "../../constants/constant";
import Title from "../CommonComponents/Title/Title";

// const ExpandMore = styled((props) => {
//   const { expand, ...other } = props;
//   return <IconButton {...other} />;
// })(({ theme, expand }) => ({
//   transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
//   marginLeft: "auto",
//   transition: theme.transitions.create("transform", {
//     duration: theme.transitions.duration.shortest,
//   }),
// }));
function Rpo() {
  // const [expanded, setExpanded] = React.useState(false);

  // const handleExpandClick = () => {
  //   setExpanded(!expanded);
  // };
  const navigate = useNavigate();

  return (
    <Paper
      component={Stack}
      id="rpo"
      sx={{
        textAlign: "center",
        justifyContent: "center",
        position: "relative",
        display: "block",
        padding: "80px 0px",
        backgroundImage: {
          md: `url(${rpoData.bgImage})`,
          xs: `url(${rpoData.bgImageMobile})`,
        },
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Grid container spacing={2}>
        <Grid xs={12} sm={12} md={6} sx={{ padding: "0px 20px" }}>
          <Title title={rpoData.name} />
          <List>
            {rpoData &&
              rpoData.content &&
              rpoData.content.length > 0 &&
              rpoData.content.map((item, index) => {
                return (
                  <ListItem key={index}>
                    <ListItemText
                      sx={{ textAlign: "justify" }}
                      primary={item}
                    />
                  </ListItem>
                );
              })}
          </List>
        </Grid>
      </Grid>
      <Button
        variant="contained"
        sx={{
          alignSelf: "center",
          marginTop: "10vh",
          backgroundColor: constants.themeColor,
        }}
        onClick={() => navigate("/rpo-details")}
      >
        {rpoData.seeMore}
      </Button>
    </Paper>

    // <Card
    //   // xs={12}
    //   // sm={9}
    //   sx={{ position: "relative", display: "block", padding: "70px 0px" }}
    //   id="about"
    // >
    //   <CardHeader
    //     avatar={
    //       <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
    //         R
    //       </Avatar>
    //     }
    //     action={
    //       <IconButton aria-label="settings">
    //         <MoreVertIcon />
    //       </IconButton>
    //     }
    //     title="Shrimp and Chorizo Paella"
    //     subheader="September 14, 2016"
    //   />
    //   <CardMedia
    //     component="img"
    //     height="500"
    //     image="https://cdn.pixabay.com/photo/2023/05/20/20/39/european-roller-8007340__340.jpg"
    //     alt="Paella dish"
    //   />
    //   <CardContent>
    //     <Typography variant="body2" color="text.secondary">
    //       This impressive paella is a perfect party dish and a fun meal to cook
    //       together with your guests. Add 1 cup of frozen peas along with the
    //       mussels, if you like.
    //     </Typography>
    //   </CardContent>
    //   <CardActions disableSpacing>
    //     <IconButton aria-label="add to favorites">
    //       <FavoriteIcon />
    //     </IconButton>
    //     <IconButton aria-label="share">
    //       <ShareIcon />
    //     </IconButton>
    //     <ExpandMore
    //       expand={expanded}
    //       onClick={handleExpandClick}
    //       aria-expanded={expanded}
    //       aria-label="show more"
    //     >
    //       <ExpandMoreIcon />
    //     </ExpandMore>
    //   </CardActions>
    //   <Collapse in={expanded} timeout="auto" unmountOnExit>
    //     <CardContent>
    //       <Typography paragraph>Method:</Typography>
    //       <Typography paragraph>
    //         Heat 1/2 cup of the broth in a pot until simmering, add saffron and
    //         set aside for 10 minutes.
    //       </Typography>
    //       <Typography paragraph>
    //         Heat oil in a (14- to 16-inch) paella pan or a large, deep skillet
    //         over medium-high heat. Add chicken, shrimp and chorizo, and cook,
    //         stirring occasionally until lightly browned, 6 to 8 minutes.
    //         Transfer shrimp to a large plate and set aside, leaving chicken and
    //         chorizo in the pan. Add pimentón, bay leaves, garlic, tomatoes,
    //         onion, salt and pepper, and cook, stirring often until thickened and
    //         fragrant, about 10 minutes. Add saffron broth and remaining 4 1/2
    //         cups chicken broth; bring to a boil.
    //       </Typography>
    //       <Typography paragraph>
    //         Add rice and stir very gently to distribute. Top with artichokes and
    //         peppers, and cook without stirring, until most of the liquid is
    //         absorbed, 15 to 18 minutes. Reduce heat to medium-low, add reserved
    //         shrimp and mussels, tucking them down into the rice, and cook again
    //         without stirring, until mussels have opened and rice is just tender,
    //         5 to 7 minutes more. (Discard any mussels that don&apos;t open.)
    //       </Typography>
    //       <Typography>
    //         Set aside off of the heat to let rest for 10 minutes, and then
    //         serve.
    //       </Typography>
    //     </CardContent>
    //   </Collapse>
    // </Card>
  );
}

export default Rpo;
