import "./App.css";
import Header from "./components/Header/Header";
import { Route, Routes } from "react-router-dom";
import HomeContainer from "./components/HomeContainer/HomeContainer";
import RpoDetails from "./components/RpoDetails/RpoDetails";
import SwDevelopmentDetails from "./components/SwDevelopmentDetails/SwDevelopmentDetails";
import Footer from "./components/Footer/Footer";
import AryaInfolabsServicesDetails from "./components/AryaInfolabsServicesDetails/AryaInfolabsServicesDetails";
import SocialMediaStickyIcons from "./components/SocialMediaStickyIcons/SocialMediaStickyIcons";

function App() {
  return (
    <>
      <Header />
      <SocialMediaStickyIcons />
      <Routes>
        <Route>
          <Route index element={<HomeContainer />} />
          <Route exact path="/:id" element={<HomeContainer />} />
        </Route>
        <Route exact path="/rpo-details" element={<RpoDetails />} />
        <Route
          exact
          path="/sw-development-details"
          element={<SwDevelopmentDetails />}
        />
        <Route
          exact
          path="/ai-services-details"
          element={<AryaInfolabsServicesDetails />}
        />
        <Route path="*" element={<HomeContainer />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
