import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import "./SimpleCard.css";
import constants from "../../constants/constant";

function SimpleCard(props) {
  const { item, xs, sm, md, lg, xl, index } = props;

  const [expandedItems, setExpandedItems] = useState([]);

  const toggleExpand = (index) => {
    setExpandedItems((prevExpandedItems) =>
      prevExpandedItems.includes(index)
        ? prevExpandedItems.filter((item) => item !== index)
        : [...prevExpandedItems, index]
    );
  };

  return (
    <Grid
      xs={xs}
      sm={sm}
      md={md}
      lg={lg}
      xl={xl}
      sx={{ padding: "10px" }}
      key={index}
    >
      <Card
        className="card"
        sx={{
          ":hover": {
            boxShadow: 20, // theme.shadows[20]
            transform: "scale(1.05)", // zoom on hover
          },
        }}
        // onClick={() => toggleExpand(index)}
      >
        <CardActionArea>
          <CardMedia
            className="cardImage"
            component="img"
            height="125"
            image={item?.image}
            alt={item?.title}
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {item?.title}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {expandedItems.includes(index)
                ? item.description
                : `${item.description.slice(0, 100)}${
                    item.description.length > 100 ? "..." : ""
                  }`}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <Button
            size="small"
            onClick={() => toggleExpand(index)}
            sx={{ color: constants.themeColor }}
          >
            {expandedItems.includes(index) ? "See less" : "See more"}
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
}

export default SimpleCard;
