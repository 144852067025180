import { Box } from "@mui/material";
import React from "react";
import Home from "../Home/Home";
import About from "../About/About";
import Rpo from "../Rpo/Rpo";
import SWDevelopment from "../SWDevelopment/SWDevelopment";
import Gallery from "../Gallery/Gallery";
import ContactUs from "../ContactUs/ContactUs";
import Testimonials from "../Testimonials/Testimonials";
import AryaInfolabsServices from "../AryaInfolabsServices/AryaInfolabsServices";

function HomeContainer() {
  return (
    <Box>
      <Home />
      <About />
      <AryaInfolabsServices />
      <SWDevelopment />
      <Rpo />
      <Gallery />
      <Testimonials />
      <ContactUs />
    </Box>
  );
}

export default HomeContainer;
