import {
  Box,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  Stack,
} from "@mui/material";
import aboutData from "./data.json";
import React from "react";
import Title from "../CommonComponents/Title/Title";

function About() {
  return (
    <Paper
      id="about"
      sx={{
        position: "relative",
        display: "block",
        padding: "80px 0px",
        backgroundImage: `url(${aboutData.bgImage})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Stack
        direction={{ xs: "column", sm: "column", md: "row" }}
        divider={<Divider orientation="vertical" flexItem />}
        spacing={2}
        sx={{ textAlign: "center", justifyContent: "center" }}
      >
        <Grid
          spacing={2}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Box
            component="img"
            alt={aboutData.name}
            src={aboutData.image}
            sx={{
              borderRadius: "50%",
              maxWidth: "-webkit-fill-available",
              padding: "0px 15px",
            }}
          />
        </Grid>
        <Grid
          spacing={2}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Box sx={{ textAlign: "center" }}>
            <Title title={aboutData.name} />
          </Box>
          <List>
            {aboutData &&
              aboutData.content &&
              aboutData.content.length > 0 &&
              aboutData.content.map((item, index) => {
                return (
                  <ListItem key={index}>
                    <ListItemText
                      sx={{ textAlign: "justify" }}
                      primary={item}
                    />
                  </ListItem>
                );
              })}
          </List>
        </Grid>
      </Stack>
    </Paper>
  );
}

export default About;
