import {
  Alert,
  Box,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import contactUsData from "./data.json";
import React, { useState } from "react";
import CustomGoogleMap from "../CustomGoogleMap/CustomGoogleMap";
import Title from "../CommonComponents/Title/Title";

function ContactUs() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    email: "",
  });

  const [showAlert, setShowAlert] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = {};
    if (!formData.name) {
      newErrors.name = "Name is required.";
    }
    if (!formData.email) {
      newErrors.email = "Email is required.";
    } else if (
      !/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(formData.email)
    ) {
      newErrors.email = "Email is invalid.";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      console.log(formData);
      setFormData({ name: "", email: "", message: "" });
      setErrors({ name: "", email: "", message: "" });
      setShowAlert(true);
    }
  };

  return (
    <Paper
      id="contact"
      sx={{
        position: "relative",
        display: "block",
        padding: "80px 0px",
        backgroundImage: `url(${contactUsData.bgImage})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Stack
        direction={{ xs: "column", sm: "column", md: "row" }}
        divider={<Divider orientation="vertical" flexItem />}
        spacing={2}
        sx={{ textAlign: "center", justifyContent: "center" }}
      >
        <Grid
          spacing={2}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "100%",
            height: "400px",
            padding: "0px 15px",
          }}
        >
          {/* <Typography
            sx={{
              typography: { sm: "h4", xs: "h5" },
              textDecoration: "underline",
              display: { xs: "block", sm: "block", md: "block", lg: "none" },
            }}
            gutterBottom
          >
            {contactUsData.name}
          </Typography> */}
          {/* <form onSubmit={handleSubmit}>
            <TextField
              id="name"
              name="name"
              label={contactUsData.formData.txtName}
              variant="standard"
              value={formData.name}
              onChange={handleChange}
              error={errors.name ? true : false}
              helperText={errors.name}
              fullWidth
            />
            <TextField
              id="email"
              name="email"
              label={contactUsData.formData.txtEmail}
              variant="standard"
              helperText={errors.email}
              value={formData.email}
              onChange={handleChange}
              error={errors.email ? true : false}
              fullWidth
            />
            <Grid item sx={{ width: { lg: "500px", md: "500px" } }}>
              <TextField
                id="message"
                name="message"
                label={contactUsData.formData.txtMessage}
                multiline
                rows={4}
                variant="standard"
                value={formData.message}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Button
              type="submit"
              variant="contained"
              sx={{ marginTop: "20px" }}
              // fullWidth
            >
              {contactUsData.formData.submit}
              <SendIcon sx={{ marginLeft: "10px" }} />
            </Button>
          </form> */}
          <CustomGoogleMap lat={23.033863} lng={72.585022} />
        </Grid>
        <Grid
          spacing={2}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "left",
            width: "100%",
            padding: "0px 15px",
            // paddingLeft: { lg: "350px", sm: "300px", md: "150px" },
          }}
        >
          <Title title={contactUsData.name} />
          <Typography
            sx={{
              typography: { sm: "h5", xs: "h6" },
            }}
            gutterBottom
          >
            {contactUsData.getInTouch.contactUs}
          </Typography>
          <Box component="div">
            <Typography
              sx={{
                typography: { sm: "h6", xs: "h7" },
                fontWeight: 700,
              }}
              gutterBottom
            >
              <LocationOnIcon
                sx={{ marginRight: "10px", marginBottom: "-7px" }}
              />
              {contactUsData.getInTouch.address.name}
            </Typography>
            <Typography sx={{ paddingLeft: "35px" }}>
              {contactUsData.getInTouch.address.value}
            </Typography>
          </Box>
          <Box component="div">
            <Typography
              sx={{
                typography: { sm: "h6", xs: "h7" },
                fontWeight: 700,
              }}
              gutterBottom
            >
              <LocalPhoneIcon
                sx={{ marginRight: "10px", marginBottom: "-7px" }}
              />
              {contactUsData.getInTouch.phone.name}
            </Typography>
            <Typography sx={{ paddingLeft: "35px" }}>
              {contactUsData.getInTouch.phone.value}
            </Typography>
          </Box>
          <Box component="div">
            <Typography
              sx={{
                typography: { md: "h6", sm: "h6", xs: "h7" },
                fontWeight: 700,
              }}
              gutterBottom
            >
              <EmailIcon sx={{ marginRight: "10px", marginBottom: "-7px" }} />
              {contactUsData.getInTouch.email.name}
            </Typography>
            <Typography sx={{ paddingLeft: "35px" }}>
              {contactUsData.getInTouch.email.value}
            </Typography>
          </Box>
        </Grid>
      </Stack>
      {showAlert && (
        <Alert variant="filled" severity="success" sx={{ marginTop: "10px" }}>
          {contactUsData.alertMessage}
        </Alert>
      )}
    </Paper>
  );
}

export default ContactUs;
