import {
  Box,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import AryaInfolabsServicesDetailsData from "./data.json";
import React, { useEffect } from "react";
import SimpleCard from "../CustomCard/SimpleCard";
import "./AryaInfolabsServicesDetails.css";
import Title from "../CommonComponents/Title/Title";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import CustomGoogleMap from "../CustomGoogleMap/CustomGoogleMap";

function AryaInfolabsServicesDetails() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, []);

  return (
    <Paper
      id="rpoDetails"
      component={Stack}
      sx={{
        position: "relative",
        display: "block",
        // padding: "80px 0px",
        backgroundImage: `url(${AryaInfolabsServicesDetailsData.bgImage})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Grid xs={12} sm={12} md={6} sx={{ padding: "0px 15px" }}>
        <Title title={AryaInfolabsServicesDetailsData.name} />
      </Grid>
      <List>
        <ListItem>
          <ListItemText
            sx={{ textAlign: "justify" }}
            primary={AryaInfolabsServicesDetailsData.description}
          />
        </ListItem>
      </List>
      <Box
        sx={{
          textAlign: "center",
          margin: "20px 0px 40px 0px",
          padding: "0px 15px",
        }}
      >
        <Title title={AryaInfolabsServicesDetailsData.whyChose} />
      </Box>
      <Grid container spacing={2} className="container">
        {AryaInfolabsServicesDetailsData.whyChoseCards &&
          AryaInfolabsServicesDetailsData.whyChoseCards.length > 0 &&
          AryaInfolabsServicesDetailsData.whyChoseCards.map((item, index) => {
            return (
              <SimpleCard
                item={item}
                xs={12}
                sm={6}
                md={4}
                lg={4}
                xl={4}
                index={index}
                key={index}
              />
            );
          })}
      </Grid>
      <Grid
        spacing={2}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          textAlign: "left",
          width: "100%",
          padding: "0px 15px",
          // paddingLeft: { lg: "350px", sm: "300px", md: "150px" },
        }}
      >
        <Stack
          direction={{ xs: "column", sm: "column", md: "row" }}
          divider={<Divider orientation="vertical" flexItem />}
          spacing={2}
          sx={{
            textAlign: "center",
            justifyContent: "center",
            padding: "80px 0px",
          }}
        >
          <Grid
            spacing={2}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              width: "100%",
              height: "400px",
              padding: "0px 15px",
            }}
          >
            <CustomGoogleMap lat={41.2307} lng={-73.0640  } />
          </Grid>
          <Grid
            spacing={2}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              textAlign: "left",
              width: "100%",
              padding: "0px 15px",
              // paddingLeft: { lg: "350px", sm: "300px", md: "150px" },
            }}
          >
            <Title title={AryaInfolabsServicesDetailsData.getInTouch.name} />
            <Typography
              sx={{
                typography: { sm: "h5", xs: "h6" },
              }}
              gutterBottom
            >
              {AryaInfolabsServicesDetailsData.getInTouch.getInTouch.contactUs}
            </Typography>
            <Box component="div">
              <Typography
                sx={{
                  typography: { sm: "h6", xs: "h7" },
                  fontWeight: 700,
                }}
                gutterBottom
              >
                <LocationOnIcon
                  sx={{ marginRight: "10px", marginBottom: "-7px" }}
                />
                {
                  AryaInfolabsServicesDetailsData.getInTouch.getInTouch.address
                    .name
                }
              </Typography>
              <Typography sx={{ paddingLeft: "35px" }}>
                {
                  AryaInfolabsServicesDetailsData.getInTouch.getInTouch.address
                    .value
                }
              </Typography>
            </Box>
            <Box component="div">
              <Typography
                sx={{
                  typography: { sm: "h6", xs: "h7" },
                  fontWeight: 700,
                }}
                gutterBottom
              >
                <LocalPhoneIcon
                  sx={{ marginRight: "10px", marginBottom: "-7px" }}
                />
                {
                  AryaInfolabsServicesDetailsData.getInTouch.getInTouch.phone
                    .name
                }
              </Typography>
              <Typography sx={{ paddingLeft: "35px" }}>
                {
                  AryaInfolabsServicesDetailsData.getInTouch.getInTouch.phone
                    .value
                }
              </Typography>
            </Box>
            <Box component="div">
              <Typography
                sx={{
                  typography: { md: "h6", sm: "h6", xs: "h7" },
                  fontWeight: 700,
                }}
                gutterBottom
              >
                <EmailIcon sx={{ marginRight: "10px", marginBottom: "-7px" }} />
                {
                  AryaInfolabsServicesDetailsData.getInTouch.getInTouch.email
                    .name
                }
              </Typography>
              <Typography sx={{ paddingLeft: "35px" }}>
                {
                  AryaInfolabsServicesDetailsData.getInTouch.getInTouch.email
                    .value
                }
              </Typography>
            </Box>
          </Grid>
        </Stack>
      </Grid>
    </Paper>
  );
}

export default AryaInfolabsServicesDetails;
