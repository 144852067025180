import { Typography } from "@mui/material";
import React from "react";
import "./Title.css";

function Title(props) {
  const { title } = props;

  return (
    <Typography
      className="title"
      sx={{
        typography: { sm: "h4", xs: "h5" },
        position: "relative",
        display: "inline - block",
        width: "fit-content",
        marginTop: "25px",
      }}
      gutterBottom
    >
      {title}
    </Typography>
  );
}

export default Title;
