import {
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  Stack,
} from "@mui/material";
import React from "react";
import rpoData from "./data.json";
import { useNavigate } from "react-router-dom";
import constants from "../../constants/constant";
import Title from "../CommonComponents/Title/Title";

// const ExpandMore = styled((props) => {
//   const { expand, ...other } = props;
//   return <IconButton {...other} />;
// })(({ theme, expand }) => ({
//   transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
//   marginLeft: "auto",
//   transition: theme.transitions.create("transform", {
//     duration: theme.transitions.duration.shortest,
//   }),
// }));
function AryaInfolabsServices() {
  // const [expanded, setExpanded] = React.useState(false);

  // const handleExpandClick = () => {
  //   setExpanded(!expanded);
  // };
  const navigate = useNavigate();

  return (
    <Paper
      component={Stack}
      id="ai-services"
      sx={{
        textAlign: "center",
        justifyContent: "center",
        position: "relative",
        display: "block",
        padding: "80px 0px",
        backgroundImage: {
          md: `url(${rpoData.bgImage})`,
          xs: `url(${rpoData.bgImageMobile})`,
        },
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Grid container spacing={2}>
        <Grid xs={12} sm={12} md={6} sx={{ padding: "0px 20px" }}>
          <Title title={rpoData.name} />
          <List>
            {rpoData &&
              rpoData.content &&
              rpoData.content.length > 0 &&
              rpoData.content.map((item, index) => {
                return (
                  <ListItem key={index}>
                    <ListItemText
                      sx={{ textAlign: "justify" }}
                      primary={item}
                    />
                  </ListItem>
                );
              })}
          </List>
        </Grid>
      </Grid>
      <Button
        variant="contained"
        sx={{
          alignSelf: "center",
          marginTop: "10vh",
          backgroundColor: constants.themeColor,
        }}
        onClick={() => navigate("/ai-services-details")}
      >
        {rpoData.seeMore}
      </Button>
    </Paper>
  );
}

export default AryaInfolabsServices;
