import { Box, IconButton, Paper, Stack, Typography } from "@mui/material";
import React from "react";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import footerData from "./data.json";

function Footer() {
  return (
    <Paper
      component={Stack}
      id="footer"
      sx={{
        textAlign: "center",
        justifyContent: "center",
        position: "relative",
        display: "block",
        padding: "40px 0px",
      }}
    >
      <Box sx={{ alignSelf: "center", textAlign: "center" }}>
        <IconButton
          aria-label={footerData.socialMedia.facebook.name}
          sx={{
            border: "2px solid",
            borderRadius: "50%",
            padding: "10px",
            ":hover": {
              boxShadow: 20, // theme.shadows[20]
              transform: "scale(1.05)", // zoom on hover
            },
          }}
          href={footerData.socialMedia.facebook.value}
          target="_blank"
        >
          <FacebookIcon />
        </IconButton>
        <IconButton
          aria-label={footerData.socialMedia.twitter.value}
          sx={{
            border: "2px solid",
            borderRadius: "50%",
            padding: "10px",
            marginLeft: "30px",
            marginRight: "30px",
            ":hover": {
              boxShadow: 20, // theme.shadows[20]
              transform: "scale(1.05)", // zoom on hover
            },
          }}
          href={footerData.socialMedia.twitter.value}
          target="_blank"
        >
          <TwitterIcon />
        </IconButton>
        <IconButton
          aria-label={footerData.socialMedia.linkedIn.name}
          sx={{
            border: "2px solid",
            borderRadius: "50%",
            padding: "10px",
            ":hover": {
              boxShadow: 20, // theme.shadows[20]
              transform: "scale(1.05)", // zoom on hover
            },
          }}
          href={footerData.socialMedia.linkedIn.value}
          target="_blank"
        >
          <LinkedInIcon />
        </IconButton>
      </Box>
      <Box component="div" sx={{ margin: "10px 0px" }}></Box>
      <Typography
        sx={{
          typography: { sm: "h7", xs: "h7" },
          letterSpacing: ".3rem",
          fontWeight: 900,
        }}
        gutterBottom
      >
        {footerData.title}
      </Typography>
    </Paper>
  );
}

export default Footer;
