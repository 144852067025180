import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  Stack,
} from "@mui/material";
import rpoDetailsData from "./data.json";
import React, { useEffect } from "react";
import SimpleCard from "../CustomCard/SimpleCard";
import "./RpoDetails.css";
import Title from "../CommonComponents/Title/Title";

function RpoDetails() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, []);

  return (
    <Paper
      id="rpoDetails"
      component={Stack}
      sx={{
        position: "relative",
        display: "block",
        // padding: "80px 0px",
        backgroundImage: `url(${rpoDetailsData.bgImage})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Grid xs={12} sm={12} md={6} sx={{ padding: "0px 15px" }}>
        <Title title={rpoDetailsData.name} />
      </Grid>
      <List>
        <ListItem>
          <ListItemText
            sx={{ textAlign: "justify" }}
            primary={rpoDetailsData.description}
          />
        </ListItem>
      </List>
      <Grid
        container
        spacing={2}
        sx={{
          backgroundImage: `url(${rpoDetailsData.service1.bgImage})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Grid
          xs={12}
          sm={12}
          md={6}
          sx={{
            textAlign: "center",
            justifyContent: "center",
            position: "relative",
            display: "block",
            padding: "80px 20px",
          }}
        >
          <Title title={rpoDetailsData.service1.title} />
          <List>
            <ListItem>
              <ListItemText
                sx={{ textAlign: "justify" }}
                primary={rpoDetailsData.service1.description}
              />
            </ListItem>
          </List>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        sx={{
          backgroundImage: {
            md: `url(${rpoDetailsData.service2.bgImage})`,
            xs: `url(${rpoDetailsData.service2.bgImageMobile})`,
          },
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Grid
          xs={12}
          sm={12}
          md={6}
          sx={{
            textAlign: "center",
            justifyContent: "center",
            position: "relative",
            display: { xs: "none", sm: "none", md: "block" },
            padding: "80px 20px",
          }}
        ></Grid>
        <Grid
          xs={12}
          sm={12}
          md={6}
          sx={{
            textAlign: "center",
            justifyContent: "center",
            position: "relative",
            display: "block",
            padding: "80px 20px",
          }}
        >
          <Title title={rpoDetailsData.service2.title} />
          <List>
            <ListItem>
              <ListItemText
                sx={{ textAlign: "justify" }}
                primary={rpoDetailsData.service2.description}
              />
            </ListItem>
          </List>
        </Grid>
      </Grid>
      <Box
        sx={{ textAlign: "center", margin: "50px 0px", padding: "0px 15px" }}
      >
        <Title title={rpoDetailsData.whyChose} />
      </Box>
      <Grid container spacing={2} className="container">
        {rpoDetailsData.cards &&
          rpoDetailsData.cards.length > 0 &&
          rpoDetailsData.cards.map((item, index) => {
            return (
              <SimpleCard
                item={item}
                xs={12}
                sm={6}
                md={4}
                lg={3}
                xl={3}
                index={index}
                key={index}
              />
            );
          })}
      </Grid>
    </Paper>
  );
}

export default RpoDetails;
