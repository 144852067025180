import { Button, Typography, Paper, Stack } from "@mui/material";
import homeData from "./data.json";
import React, { useState, useEffect } from "react";
import "./Home.css";
import constants from "../../constants/constant";

function Home() {
  const [currentTextAmbitionsRealized, setCurrentTextAmbitionsRealized] =
    useState("");
  const [currentIndexAmbitionsRealized, setCurrentIndexAmbitionsRealized] =
    useState(0);
  const [currentTextDetails, setCurrentTextDetails] = useState("");
  const [currentIndexDetails, setCurrentIndexDetails] = useState(0);

  useEffect(() => {
    let timeout;
    const text = homeData.ambitionsRealized;

    if (currentIndexAmbitionsRealized < text.length) {
      timeout = setTimeout(() => {
        setCurrentTextAmbitionsRealized(
          (prevText) => prevText + text[currentIndexAmbitionsRealized]
        );
        setCurrentIndexAmbitionsRealized((prevIndex) => prevIndex + 1);
      }, 100);
    }
    return () => clearTimeout(timeout);
  }, [currentIndexAmbitionsRealized]);

  useEffect(() => {
    let timeout;
    const text = homeData.details.split(" ");

    if (currentIndexDetails < text.length) {
      timeout = setTimeout(() => {
        setCurrentTextDetails(
          (prevText) => prevText + text[currentIndexDetails] + " "
        );
        setCurrentIndexDetails((prevIndex) => prevIndex + 1);
      }, 100);
    }
    return () => clearTimeout(timeout);
  }, [currentIndexDetails]);

  return (
    <Paper
      component={Stack}
      id="home"
      sx={{
        textAlign: "center",
        justifyContent: "center",
        position: "relative",
        display: "block",
        padding: "190px 0px",
        backgroundImage: `url(${homeData.bgImage})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Typography
        className="tagLine"
        sx={{
          typography: { sm: "h1", xs: "h2" },
          color: "white",
          fontWeight: "900 !important",
        }}
        gutterBottom
      >
        {currentTextAmbitionsRealized}
      </Typography>
      <Typography
        sx={{ typography: { sm: "h5", xs: "h6" }, color: "white" }}
        gutterBottom
      >
        {currentTextDetails}
      </Typography>
      <Button
        variant="contained"
        sx={{
          alignSelf: "center",
          marginTop: "10vh",
          backgroundColor: constants.themeColor,
        }}
        href="#about"
      >
        {homeData.exploreMore}
      </Button>
    </Paper>
  );
}

export default Home;
